<template>
    <div>
        <div v-if="preloading">
            <button class="btn btn-secondary"><i class="fas fa-cog fa-spin" /> {{ $t('global.loading') }}...</button>
        </div>
        <div v-else>
            <div v-if="authorised" class="alert alert-success">
                Merci ! Votre paiement a bien été accepté. Vos crédits vous seront attribués dans un instant.
            </div>
            <div v-else class="alert alert-danger">
                Désolé ! Une erreur est survenue lors de votre paiement. Si besoin, n'hésitez pas à nous contacter afin
                de régler le problème.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AchatCreditsConfirmation',
        data() {
            return {
                authorised: false,
                preloading: false
            }
        },
        created() {
            this.preloading = true
            this.checkTransStatus()
        },
        methods: {
            checkTransStatus() {
                console.log('route', this.$route);
                if (this.$route.query.status === 'COMPLETED') {
                    this.authorised = true
                }
                if (this.$route.query.vads_trans_status === 'AUTHORISED') {
                    this.authorised = true
                }
                this.preloading = false
            }
        }
    }
</script>
